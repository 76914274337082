@include media-breakpoint-down(sm) {
  .h5 {
    font-size: 1.15rem;
  }
  .bg-light {
    background-color: transparent !important;
  }
  .nav-link {
    padding: 0.5rem 0.75rem !important;
  }
  .card-body {
    padding: 0 0.5rem !important;
  }
  #send-money .jumbo-input {
    font-size: 2rem;
  }
}
