.d-flex,
.row {
  min-height: 0; // needed to allow for flex column components to shrink past its contents
  min-width: 0; // needed to allow for flex row components to shrink past its contents
}

.bg-gp-gradient {
  background-image: linear-gradient(355deg, $secondary 16%, $primary 90%);
  background: -webkit-linear-gradient(to right, $secondary, $primary);
  background: linear-gradient(to right, $secondary, $primary);
  background-image: radial-gradient(circle farthest-corner at 10% 20%, $secondary 0%, $primary 90%);
  background: linear-gradient(64.5deg, $secondary 14.7%, $primary 88.7%);
}

.bg-gp-gradient-alt {
  background: linear-gradient(64.5deg, #afa2fb 14.7%, #ffdccc 88.7%);
}

.text-gp-gradient {
  @extend .bg-gp-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rounded-gp {
  border-radius: 1.5rem !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-reference {
  font-size: 0.5rem;
}

.btn {
  &:disabled,
  &.disabled {
    background-color: $gray-400;
    border-color: $gray-400;
    opacity: 1;
  }
}

.cvv-input {
  width: 3rem;
  text-align: center;
}

.gp-icon {
  width: 3rem;
  height: 3rem;
}

.gp-nav-icon {
  width: 2.5rem;
  height: 2.5rem;
  stroke-width: 1.5;
}

.nav-link.active .gp-nav-icon {
  stroke-width: 2;
}

.btn-invisible {
  &:focus,
  &:active {
    box-shadow: none;
  }
}

.editable-credit-card {
  .expiration-field {
    width: 6.5rem;
  }
  .cvv-field {
    width: 3rem;
  }
}

#app-loader {
  .gopay-logo {
    height: 50px;
    fill: $primary;
    -webkit-animation: pulsate 3s infinite ease-out;
    -moz-animation: pulsate 3s infinite ease-out;
    -ms-animation: pulsate 3s infinite ease-out;
    -o-animation: pulsate 3s infinite ease-out;
    animation: pulsate 3s infinite ease-out;
    @-webkit-keyframes pulsate {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }
    @-moz-keyframes pulsate {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }
    @-ms-keyframes pulsate {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }
    @-o-keyframes pulsate {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes pulsate {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

#login {
  .login-content {
    height: 14rem;
  }
  .form-control:focus {
    border-color: transparent;
    box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width rgba(white, $input-btn-focus-color-opacity);
  }
  .verification-code-action {
    height: 2rem;
  }
  .btn-outline-light {
    // color: white !important;
    @include button-variant(
      transparent,
      white,
      white,
      // hover
      tint-color(transparent, 25),
      tint-color(transparent, 25),
      white,
      // active
      tint-color(transparent, 25),
      tint-color(transparent, 25),
      white,
      // disabled
      shade-color(white, 30),
      shade-color(white, 30),
      shade-color(white, 30)
    );
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.main {
  .navbar-brand {
    padding-right: 1em;
  }
  .navbar-toggler {
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  .nav-link:not(.disabled) {
    color: $body-color;
    padding-left: 0;
    &.active,
    &:hover {
      color: $primary;
    }
    &.active {
      font-weight: bold;
    }
  }
}

#send-money {
  .jumbo-input {
    font-size: 2rem;
    line-height: 1;
    &,
    &:focus {
      color: $primary;
    }
    &::placeholder {
      // color: $secondary;
      // box-shadow: none;
    }
    &:focus::placeholder {
      color: transparent !important;
    }
  }
}
