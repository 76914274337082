@import 'react-intl-tel-input/dist/main.css';

.intl-tel-input {
  width: 100%;
  .selected-flag {
    background-color: transparent !important;
  }
  &.allow-dropdown input.form-control {
    text-align: center;
    padding-left: 10px;
  }
  &.expanded .form-control {
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
  }
  .flag-container .country-list {
    width: 100%;
    margin-top: 5px;
  }
}
