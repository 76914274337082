/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import '~bootstrap/scss/functions';

$primary: 
// #8360c3;
  #7a21ff;
$secondary: 
// #2ebf91;
  #57b045;

// $input-btn-focus-box-shadow: none;
// $input-focus-box-shadow: none;
$input-placeholder-color: #adb5bd;
$input-group-addon-bg: white;

$nav-link-hover-color: white;
$dropdown-link-hover-color: white;
$dropdown-link-hover-bg: $primary;
$dropdown-link-active-color: white;
$dropdown-link-active-bg: $primary;

$table-cell-padding-y: 0.75rem;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

$utilities: map-merge(
  $utilities,
  (
    'border-start':
      map-merge(
        map-get($utilities, 'border-start'),
        (
          property: border-left,
          class: border-start,
          responsive: true,
          values: map-get(map-get($utilities, 'border-start'), 'values'),
        )
      ),
    'border-top':
      map-merge(
        map-get($utilities, 'border-top'),
        (
          property: border-top,
          class: border-top,
          responsive: true,
          values: map-get(map-get($utilities, 'border-top'), 'values'),
        )
      ),
  )
);

// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';
// scss-docs-end import-stack
